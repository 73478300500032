exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-settings-tsx": () => import("./../../../src/pages/account-settings.tsx" /* webpackChunkName: "component---src-pages-account-settings-tsx" */),
  "component---src-pages-auth-error-tsx": () => import("./../../../src/pages/auth-error.tsx" /* webpackChunkName: "component---src-pages-auth-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manager-index-tsx": () => import("./../../../src/pages/manager/index.tsx" /* webpackChunkName: "component---src-pages-manager-index-tsx" */),
  "component---src-pages-manager-manage-activities-add-section-index-tsx": () => import("./../../../src/pages/manager/manage-activities/add-section/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-activities-add-section-index-tsx" */),
  "component---src-pages-manager-manage-activities-index-tsx": () => import("./../../../src/pages/manager/manage-activities/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-activities-index-tsx" */),
  "component---src-pages-manager-manage-activities-sections-section-id-add-post-index-tsx": () => import("./../../../src/pages/manager/manage-activities/sections/[sectionId]/add-post/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-activities-sections-section-id-add-post-index-tsx" */),
  "component---src-pages-manager-manage-activities-sections-section-id-index-tsx": () => import("./../../../src/pages/manager/manage-activities/sections/[sectionId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-activities-sections-section-id-index-tsx" */),
  "component---src-pages-manager-manage-activities-sections-section-id-posts-post-id-edit-index-tsx": () => import("./../../../src/pages/manager/manage-activities/sections/[sectionId]/posts/[postId]/edit/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-activities-sections-section-id-posts-post-id-edit-index-tsx" */),
  "component---src-pages-manager-manage-activities-sections-section-id-posts-post-id-index-tsx": () => import("./../../../src/pages/manager/manage-activities/sections/[sectionId]/posts/[postId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-activities-sections-section-id-posts-post-id-index-tsx" */),
  "component---src-pages-manager-manage-notifications-broadcast-notification-index-tsx": () => import("./../../../src/pages/manager/manage-notifications/broadcast-notification/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-notifications-broadcast-notification-index-tsx" */),
  "component---src-pages-manager-manage-notifications-index-tsx": () => import("./../../../src/pages/manager/manage-notifications/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-notifications-index-tsx" */),
  "component---src-pages-manager-manage-notifications-pending-notifications-index-tsx": () => import("./../../../src/pages/manager/manage-notifications/pending-notifications/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-notifications-pending-notifications-index-tsx" */),
  "component---src-pages-manager-manage-teaching-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-add-term-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/add-term/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-add-term-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-add-course-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/add-course/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-add-course-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-add-group-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/add-group/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-add-group-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-courses-course-id-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/courses/[courseId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-courses-course-id-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-courses-course-id-registrations-registration-id-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/courses/[courseId]/registrations/[registrationId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-courses-course-id-registrations-registration-id-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-groups-group-id-courses-course-id-lectures-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/groups/[groupId]/courses/[courseId]/lectures/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-groups-group-id-courses-course-id-lectures-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-groups-group-id-courses-course-id-lectures-lecture-id-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/groups/[groupId]/courses/[courseId]/lectures/[lectureId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-groups-group-id-courses-course-id-lectures-lecture-id-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-groups-group-id-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/groups/[groupId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-groups-group-id-index-tsx" */),
  "component---src-pages-manager-manage-teaching-manage-terms-term-id-index-tsx": () => import("./../../../src/pages/manager/manage-teaching/manage-terms/[termId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-teaching-manage-terms-term-id-index-tsx" */),
  "component---src-pages-manager-manage-trips-add-trip-index-tsx": () => import("./../../../src/pages/manager/manage-trips/add-trip/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-trips-add-trip-index-tsx" */),
  "component---src-pages-manager-manage-trips-index-tsx": () => import("./../../../src/pages/manager/manage-trips/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-trips-index-tsx" */),
  "component---src-pages-manager-manage-trips-trip-id-index-tsx": () => import("./../../../src/pages/manager/manage-trips/[tripId]/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-trips-trip-id-index-tsx" */),
  "component---src-pages-manager-manage-users-add-user-index-tsx": () => import("./../../../src/pages/manager/manage-users/add-user/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-users-add-user-index-tsx" */),
  "component---src-pages-manager-manage-users-index-tsx": () => import("./../../../src/pages/manager/manage-users/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-users-index-tsx" */),
  "component---src-pages-manager-manage-users-list-students-index-tsx": () => import("./../../../src/pages/manager/manage-users/list-students/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-users-list-students-index-tsx" */),
  "component---src-pages-manager-manage-users-list-users-index-tsx": () => import("./../../../src/pages/manager/manage-users/list-users/index.tsx" /* webpackChunkName: "component---src-pages-manager-manage-users-list-users-index-tsx" */),
  "component---src-pages-manager-statistics-index-tsx": () => import("./../../../src/pages/manager/statistics/index.tsx" /* webpackChunkName: "component---src-pages-manager-statistics-index-tsx" */),
  "component---src-pages-mdx-edit-index-tsx": () => import("./../../../src/pages/mdx/edit/index.tsx" /* webpackChunkName: "component---src-pages-mdx-edit-index-tsx" */),
  "component---src-pages-mdx-preview-index-tsx": () => import("./../../../src/pages/mdx/preview/index.tsx" /* webpackChunkName: "component---src-pages-mdx-preview-index-tsx" */),
  "component---src-pages-recover-account-tsx": () => import("./../../../src/pages/recover-account.tsx" /* webpackChunkName: "component---src-pages-recover-account-tsx" */),
  "component---src-pages-teacher-index-tsx": () => import("./../../../src/pages/teacher/index.tsx" /* webpackChunkName: "component---src-pages-teacher-index-tsx" */),
  "component---src-pages-teacher-user-id-index-tsx": () => import("./../../../src/pages/teacher/[userId]/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-index-tsx" */),
  "component---src-pages-teacher-user-id-pending-notifications-index-tsx": () => import("./../../../src/pages/teacher/[userId]/pending-notifications/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-pending-notifications-index-tsx" */),
  "component---src-pages-teacher-user-id-terms-index-tsx": () => import("./../../../src/pages/teacher/[userId]/terms/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-terms-index-tsx" */),
  "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-courses-course-id-lectures-index-tsx": () => import("./../../../src/pages/teacher/[userId]/terms/[termId]/groups/[groupId]/courses/[courseId]/lectures/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-courses-course-id-lectures-index-tsx" */),
  "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-courses-course-id-lectures-lecture-id-index-tsx": () => import("./../../../src/pages/teacher/[userId]/terms/[termId]/groups/[groupId]/courses/[courseId]/lectures/[lectureId]/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-courses-course-id-lectures-lecture-id-index-tsx" */),
  "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-courses-course-id-new-lecture-index-tsx": () => import("./../../../src/pages/teacher/[userId]/terms/[termId]/groups/[groupId]/courses/[courseId]/new-lecture/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-courses-course-id-new-lecture-index-tsx" */),
  "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-index-tsx": () => import("./../../../src/pages/teacher/[userId]/terms/[termId]/groups/[groupId]/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-terms-term-id-groups-group-id-index-tsx" */),
  "component---src-pages-teacher-user-id-terms-term-id-index-tsx": () => import("./../../../src/pages/teacher/[userId]/terms/[termId]/index.tsx" /* webpackChunkName: "component---src-pages-teacher-user-id-terms-term-id-index-tsx" */),
  "component---src-pages-user-index-tsx": () => import("./../../../src/pages/user/index.tsx" /* webpackChunkName: "component---src-pages-user-index-tsx" */),
  "component---src-pages-user-user-id-index-tsx": () => import("./../../../src/pages/user/[userId]/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-statistics-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/statistics/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-statistics-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-terms-term-id-certificates-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/terms/[termId]/certificates/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-terms-term-id-certificates-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-terms-term-id-courses-course-id-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/terms/[termId]/courses/[courseId]/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-terms-term-id-courses-course-id-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-terms-term-id-courses-course-id-lectures-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/terms/[termId]/courses/[courseId]/lectures/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-terms-term-id-courses-course-id-lectures-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-terms-term-id-courses-course-id-lectures-lecture-id-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/terms/[termId]/courses/[courseId]/lectures/[lectureId]/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-terms-term-id-courses-course-id-lectures-lecture-id-index-tsx" */),
  "component---src-pages-user-user-id-students-student-id-terms-term-id-index-tsx": () => import("./../../../src/pages/user/[userId]/students/[studentId]/terms/[termId]/index.tsx" /* webpackChunkName: "component---src-pages-user-user-id-students-student-id-terms-term-id-index-tsx" */),
  "component---src-pages-verify-account-tsx": () => import("./../../../src/pages/verify-account.tsx" /* webpackChunkName: "component---src-pages-verify-account-tsx" */)
}

